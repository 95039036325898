import React from "react";
import WRPSectionWizard from "../WRPSectionWizard";

export default function MVLSectionWizard(props) {
  const selectFieldsetName = "selectieCategorieenFieldset";

  const onlyWhenExpressionsForCategories = {
    binnenrenovatie: "binnenrenovatieIsAangevraagd",
    muurrenovatie: "muurIsAangevraagd",
    dakrenovatie: "dakrenovatieIsAangevraagd",
    technischeInstallaties: "elektriciteitEnSanitairAangevraagd",
    gascondensatieketel: "gascondensatieketelIsAangevraagd",
    buitenschrijnwerkGlas: "ramenEnDeurenIsAangevraagd",
    vloerrenovatie: "vloerIsAangevraagd",
    warmtepomp: "warmtepompIsAangevraagd",
    warmtepompboiler: "warmtepompboilerIsAangevraagd",
    zonneboiler: "zonneboilerIsAangevraagd",
    zonnepanelen: "zonnepanelenIsAangevraagd",
  };

  function buildSectionStructure(
    buildDefaultSection,
    buildSelectCategoriesSection
  ) {
    return [
      buildDefaultSection(0),
      buildDefaultSection(1),
      buildSelectCategoriesSection(2),
      buildDefaultSection(3),
      buildDefaultSection(4),
      buildDefaultSection(5),
      buildDefaultSection(6),
      buildDefaultSection(7),
    ];
  }

  const title = "Aanvraag Mijn VerbouwLening";

  const amountOfComponents = 7;

  const welcomeText = (
    <>
      <p>
        Via onderstaande stappen kunt u Mijn VerbouwLening, een voordelige
        <b>*</b>
        lening voor renovatie en energiebesparende maatregelen, aanvragen.
      </p>
      <p>
        <b>Controleer</b> op voorhand of u voldoet aan alle {""}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/lenen/mijn-verbouwlening"
          className="vl-link"
          rel="noreferrer"
        >
          voorwaarden
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
        om in aanmerking te komen.
      </p>
      <p>
        U kunt uw lening aanvragen via dit formulier op basis van offertes. Uw
        Energiehuis beoordeelt deze aanvraag in een volgende fase. Het is
        mogelijk om de lening in meerdere schijven op te nemen (met een totaal
        maximumbedrag van 60.000 euro, dat binnen de 36 maanden na ondertekening
        van het contract opgenomen en op maximaal 25 jaar terugbetaald moet
        worden). De uitbetaling gebeurt op basis van de ingediende{" "}
        <b>(voorschot)facturen</b>.
      </p>
      <p>
        U moet alle documenten <b>digitaal bijvoegen</b>. Zorg dat u deze ter
        beschikking hebt alvorens u start met een aanvraag.
      </p>
      <p>Overzicht documenten:</p>
      <ul>
        <li>offertes</li>
        <li>
          bewijsstukken (zoals bijvoorbeeld attest beschermde afnemer, indien
          nodig)
        </li>
        <li>recente loonbrieven</li>
        <li>Kopie van uw ID kaart</li>
      </ul>
      <p>
        Lees meer over {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          target="_blank"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/lenen/mijn-verbouwlening"
          className="vl-link"
          rel="noreferrer"
        >
          MijnVerbouwLening.
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
            aria-hidden="true"
          ></span>
        </a>
      </p>
      <p style={{ fontSize: "1.5rem" }}>
        De persoonsgegevens die u in deze aanvraag verstrekt, worden verwerkt om
        te beoordelen in hoeverre u aan de voorwaarden voldoet om in aanmerking
        te komen voor een Mijn VerbouwLening, zoals voorzien in het
        Energiebesluit van 19 november 2010. Hebt u vragen bij de manier waarop
        uw Energiehuis uw gegevens verwerkt? Dan kunt u steeds contact opnemen
        met de{" "}
        <a
          href="https://www.vlaanderen.be/lenen-voor-een-woning/mijn-verbouwlening/privacyverklaring-mijn-verbouwlening"
          target="_blank"
          rel="noreferrer"
        >
          functionaris voor gegevensbescherming van uw Energiehuis
        </a>
        . Daar kunt u ook steeds terecht met opmerkingen en suggesties en om de
        rechten die de regelgeving u toekent, uit te oefenen. Meer informatie
        over de verwerking van persoonsgegevens door uw Energiehuis kunt u
        vinden op{" "}
        <a
          href="https://www.vlaanderen.be/lenen-voor-een-woning/mijn-verbouwlening/privacyverklaring-mijn-verbouwlening"
          target="_blank"
          rel="noreferrer"
        >
          onze website
        </a>
        . Bent u het niet eens met de manier waarop we uw gegevens verwerken?
        Dan kunt u zich wenden tot de bevoegde toezichthoudende autoriteit.
      </p>
      <p
        style={{
          fontSize: "2rem",
          border: "2px solid black",
          padding: "5px",
          borderRadius: "5px",
          display: "inline-block",
        }}
      >
        <b>*Opgelet: Geld lenen, kost ook geld!</b>
      </p>
    </>
  );

  return (
    <WRPSectionWizard
      onlyWhenExpressionsForCategories={onlyWhenExpressionsForCategories}
      selectFieldsetName={selectFieldsetName}
      buildSectionStructure={buildSectionStructure}
      title={title}
      welcomeText={welcomeText}
      amountOfComponents={amountOfComponents}
      {...props}
    />
  );
}
