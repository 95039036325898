import React from "react";
import WRPSectionWizard from "../WRPSectionWizard";
import PropTypes from "prop-types";

export default function EPCSectionWizard(props) {
  function buildSectionStructure(
    buildDefaultSection,
    buildSelectCategoriesSection,
    buildExtraStepForContext
  ) {
    // The EPCSectionWizard is a bit different than the other section wizards
    // as there are two seperate request forms, and there are no categories
    // We also want to give the requestor the "feel" as if this is one request
    if (props.component.inputOptions.name === "aanvraag_taak_1_epc") {
      return [
        buildDefaultSection(0),
        buildExtraStepForContext(
          2,
          "gegevensEpc",
          "Gegevens EPC",
          "Dien de EPC-certificaten in",
          false
        ),
        buildExtraStepForContext(
          3,
          "aanvragerGegevens",
          "Gegevens aanvrager",
          "Vul uw gegevens (of die van de aanvrager) in.",
          false
        ),
        buildExtraStepForContext(
          4,
          "communicatieEnBetaling",
          "Communicatie en betaling",
          "Geef uw voorkeur(en) voor communicatie op",
          false
        ),
        buildExtraStepForContext(5, "overzicht", "Overzicht", "", false),
      ];
    }

    if (props.component.inputOptions.name === "aanvraag_taak_2_epc") {
      return [
        buildExtraStepForContext(
          1,
          "gegevensEigendom",
          "Gegevens eigendom",
          "Vul de gegevens van het gebouw in<br>Deze gegevens kunnen niet meer gewijzigd worden. <br>Start een nieuwe aanvraag indien u dit toch wenst.",
          true
        ),
        buildDefaultSection(1, 2),
        buildDefaultSection(2, 3),
        buildDefaultSection(3, 4),
        buildDefaultSection(4, 5),
      ];
    }
  }

  const title = "Aanvraag EPC-Labelpremie";

  const amountOfComponents = 4;

  // We need to specify this because we want to skip the first component and we need the button to submit be available on the last.
  let sectionsLength = null;
  if (props.component.inputOptions.name === "aanvraag_taak_2_epc") {
    sectionsLength = 4;
  }

  const welcomeText = (
    <>
      <p>Via onderstaande stappen kan u Mijn EPC-Labelpremie aanvragen.</p>
      <p>
        Controleer op voorhand of u voldoet aan alle voorwaarden om in
        aanmerking te komen.
      </p>

      <p>
        U kunt de premies pas aanvragen nadat u een geldig eind EPC label heeft
        laten opstellen.
      </p>

      <p>
        U moet alle documenten digitaal bijvoegen. Zorg dat u deze ter
        beschikking hebt alvorens u start met een aanvraag.
      </p>

      <p>Overzicht documenten:</p>
      <ul>
        <li>EPC-certificaten</li>
        <li>Indien relevant, bewijsstuk woonmaatschappij</li>
      </ul>
    </>
  );

  return (
    <WRPSectionWizard
      buildSectionStructure={buildSectionStructure}
      title={title}
      welcomeText={welcomeText}
      amountOfComponents={amountOfComponents}
      sectionsLength={sectionsLength}
      {...props}
    />
  );
}

EPCSectionWizard.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    components: PropTypes.array,
    sections: PropTypes.array,
    inputOptions: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
