import moment from "moment";
import { isFunction } from "lodash";

import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";

export function notifications({ notification, values, dossierType }) {
  return notification && isFunction(notification)
    ? [
        notification({
          values,
          dossierType,
        }),
      ]
    : [];
}

function formatDate(date) {
  return moment(date).format("D MMMM YYYY");
}

// common notifications
export const notificationRequestNotSubmitted = () => ({
  id: "REQUEST_INITIATED",
  title: "Aanvraag nog niet ingediend",
  message:
    "Gelieve het formulier te vervolledigen en vervolgens in te dienen. U kunt het concept steeds zelf annuleren door onder ‘vervolledig aanvraag’ op ‘uitvoeren’ te klikken en vervolgens bovenaan ‘Annuleer aanvraag’ te kiezen.",
  type: notificationTypes.WARNING,
});

export const notificationFetchingDataFailed = () => ({
  id: "FETCHING_DATA_FAILED",
  title: "Bronnen ophalen mislukt",
  message:
    "Het ophalen van noodzakelijke gegevens is mislukt. U kunt uw aanvraag verderzetten via de actie 'Haal gegevens opnieuw op'. Indien deze boodschap blijft verschijnen, sluit de aanvraag af en probeer later opnieuw.",
  type: notificationTypes.WARNING,
});

export const notificationRequestSubmitted = ({
  values: { requestDate, decisionDate, isBeforeJuly2024 },
  dossierType,
}) => {
  let message;

  if (dossierType === "mijn_verbouwlening") {
    message = `We hebben uw aanvraag van Mijn VerbouwLening goed ontvangen op ${formatDate(
      requestDate
    )}. Uw aanvraag zal nu door uw Energiehuis (zie lager) worden onderzocht. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. Mocht u tegen <strong>${formatDate(
      decisionDate
    )}</strong> nog geen antwoord hebben gekregen, kunt u best uw Energiehuis (zie lager) contacteren. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`;
  } else if (dossierType === "mijn_verbouwpremie") {
    if (isBeforeJuly2024) {
      message = `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${formatDate(
        requestDate
      )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen ons uiterste best om uw aanvraag zo snel mogelijk te behandelen. <strong>Momenteel bedraagt de gemiddelde behandeltermijn 4,5 maanden</strong>. Mocht u na de uiterlijke behandeltermijn van 8 maanden, dat is op <strong>${formatDate(
        decisionDate
      )}</strong> nog geen beslissing ontvangen hebben, kunt u ons via uw dossier hiervan verwittigen. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.
      Indien u nog bijkomende informatie of documenten wenst aan te leveren, gelieve geen nieuwe aanvraag op te starten maar dit in dit dossier te melden via de optionele dossieractie 'Stel een vraag'. Na deze melding zullen wij u de mogelijkheid geven om bijkomende informatie op te laden.`;
    } else {
      message = `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${formatDate(
        requestDate
      )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen. <strong>Momenteel bedraagt de gemiddelde behandeltermijn 4,5 maanden</strong>. Mocht u na de uiterlijke behandeltermijn van 6 maanden, dat is op <strong>${formatDate(
        decisionDate
      )}</strong>, nog geen beslissing ontvangen hebben, kunt u ons via uw dossier hiervan verwittigen. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.
      Indien u nog bijkomende informatie of documenten wenst aan te leveren, gelieve geen nieuwe aanvraag op te starten maar dit in dit dossier te melden via de optionele dossieractie 'Stel een vraag'. Na deze melding zullen wij u de mogelijkheid geven om bijkomende informatie op te laden.`;
    }
  } else if (dossierType === "epc_labelPremie") {
    message = `We hebben uw aanvraag van EPC-Labelpremie goed ontvangen op ${formatDate(
      requestDate
    )} en bekijken of uw dossier alle noodzakelijke documenten bevat. Mocht u tegen <strong>${formatDate(
      decisionDate
    )}</strong> nog geen antwoord hebben gekregen kunt u beroep aantekenen wegens het uitblijven van de beslissing. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`;
  } else {
    message = `We hebben uw aanvraag goed ontvangen en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`;
  }

  return {
    id: "BO_TREATMENT_STARTED",
    title: "Aanvraag ingediend",
    message,
    type: notificationTypes.SUCCESS,
  };
};

// notifications mijn_verbouwlening
export const notitificationDossierRejected = () => ({
  id: "DOSSIER_REJECTED",
  title: "Dossier geweigerd",
  message: `Uw energiehuis heeft uw aanvraag voor een Mijn Verbouwlening verwerkt. Helaas kan deze u niet toegekend worden. De redenen hiervoor kan u terugvinden in de beslissingsbrief die u kan raadplegen onder 'documenten en downloads' op dit dossieroverzicht.`,
  type: notificationTypes.ERROR,
});

export const notitificationDossierDecided = ({ dossierType }) => {
  let message;

  switch (dossierType) {
    case "mijn_verbouwpremie":
      message = `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kan u de beslissing van elke aangevraagde categorie raadplegen. Bij 'documenten en downloads' kan u een detail van de beslissing consulteren.`;
      break;

    case "epc_labelPremie":
      message = `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kan u de beslissing raadplegen. Bij 'documenten en downloads' kan u een detail van de beslissing consulteren.`;
      break;

    default:
      message = `We hebben uw dossier volledig behandeld. Bij 'dossierdetails' kan u de beslissing raadplegen. Bij 'documenten en downloads' kan u een detail van de beslissing consulteren.`;
      break;
  }
  return {
    id: "DOSSIER_DECIDED",
    title: "Dossier beslist",
    message,
    type: notificationTypes.SUCCESS,
  };
};

export const notitificationBeroepDecided = () => ({
  id: "BEROEP_DECIDED",
  title: "Beroep beslist",
  message: `We hebben uw beroep volledig behandeld. Bij 'dossierdetails' kan u de beslissing van uw beroep raadplegen. Bij 'documenten en downloads' kan u een detail van de beslissing consulteren.`,
  type: notificationTypes.SUCCESS,
});

// the below notification is also used in epc-labelpremie
export const notificationAdditionalAttachmentsDelivered = ({
  values: { additionalAttachmentsDelivered, decisionDate },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${formatDate(
    additionalAttachmentsDelivered
  )}</strong>. We nemen uw dossier opnieuw in behandeling en sturen u uiterlijk op ${formatDate(
    decisionDate
  )} de beslissing over uw dossier.`,
  type: notificationTypes.SUCCESS,
});

export const notificationAdditionalAttachmentsDeliveredBeroep = ({
  values: { additionalAttachmentsDeliveredBeroep },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${formatDate(
    additionalAttachmentsDeliveredBeroep
  )}</strong>. We nemen uw beroep opnieuw in behandeling.`,
  type: notificationTypes.SUCCESS,
});

export const notificationAdditionalAttachmentsDeliveredHeropening = ({
  values: { additionalAttachmentsDelivered },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_DELIVERED_HEROPENING",
  title: "Gevraagde informatie aangeleverd",
  message: `We hebben de gevraagde informatie goed ontvangen op <strong>${formatDate(
    additionalAttachmentsDelivered
  )}</strong>. We nemen uw dossier opnieuw in behandeling.`,
  type: notificationTypes.SUCCESS,
});

export const notitificationAppealWaitingTooLong = ({ dossierType }) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message = `We ontvingen uw beroep tegen het stilzitten (het ontbreken van een beslissing) van onze diensten in verband met uw aanvraag voor EPC-Labelpremie. U ontvangt hiervan een bevestiging via e-mail. We behandelen uw dossier zo snel mogelijk.`;
  } else {
    message = `We ontvingen uw beroep tegen het stilzitten (het ontbreken van een beslissing) van onze diensten in verband met uw aanvraag voor Mijn VerbouwPremie. U ontvangt hiervan een bevestiging via uw communicatie voorkeur. We behandelen uw dossier zo snel mogelijk.`;
  }
  return {
    id: "BEROEP_TEGEN_STILZITTEN_STARTED",
    title: "Beroep tegen stilzitten ingediend",
    message: message,
    type: notificationTypes.SUCCESS,
  };
};

// the below notification is also used in epc-labelpremie
export const notificationAdditionalAttachmentsNeeded = ({
  values: { additionalAttachmentsRequested },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_NEEDED",
  title: "Dossier onvolledig.",
  message: `
      Uw dossier kan niet worden afgewerkt. Laad de ontbrekende informatie/bijlage <strong> tegen ${formatDate(
        additionalAttachmentsRequested
      )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. 
      Als u niet reageert op onze vraag, handelen we uw dossier af met de informatie waarover we nu beschikken. 
      </br></br>Welke gegevens er ontbreken vindt u in de brief die u hier kan openen : <a id="download-bijkomende-stukken" href="#">Bijkomende stukken aan te leveren</a>.`,
  type: notificationTypes.WARNING,
});

export const notificationAdditionalAttachmentsNeededBeroep = ({
  values: { additionalAttachmentsRequested },
}) => ({
  id: "ADDITIONAL_ATTACHMENTS_NEEDED_BEROEP",
  title: "Dossier onvolledig",
  message: `Uw beroep kan niet worden afgewerkt. Bepaalde informatie of bijlagen ontbreken. Laad de ontbrekende informatie/bijlage tegen <strong>${formatDate(
    additionalAttachmentsRequested
  )}</strong> op via de beschikbare dossieractie 'Laad bijkomende stukken op'. Als u niet reageert op onze vraag, handelen we uw beroep af met de informatie waarover we nu beschikken.`,
  type: notificationTypes.WARNING,
});

export const notificationRequestSubmittedMvp = ({
  values: { requestDate, decisionDate },
}) => ({
  id: "BO_TREATMENT_STARTED",
  title: "Aanvraag ingediend",
  message: `We hebben uw aanvraag van Mijn VerbouwPremie goed ontvangen op ${formatDate(
    requestDate
  )} en bekijken of uw dossier alle noodzakelijke documenten bevat. We doen onze uiterste best om uw aanvraag zo snel mogelijk te behandelen maar gezien het grote aantal aanvragen zijn er nog vele wachtenden voor u. Mocht u tegen <strong>${formatDate(
    decisionDate
  )}</strong> nog geen antwoord hebben gekregen kunt u beroep aantekenen wegens het uitblijven van de beslissing. U kunt uw dossiergegevens raadplegen en downloaden via 'documenten en downloads' op dit dossieroverzicht.`,
  type: notificationTypes.SUCCESS,
});

export const notificationBeroepRequestSubmitted = ({
  values: { appealRequestDate, appealDecisionDate },
  dossierType,
}) => {
  let message;
  if (dossierType === "epc_labelPremie") {
    message = `We hebben uw beroep tegen de beslissing goed ontvangen op ${formatDate(
      appealRequestDate
    )}. We onderzoeken uw beroep en gaan na of het gegrond is. Uiterlijk op <strong>${formatDate(
      appealDecisionDate
    )}</strong>  ontvangt u onze beslissing.`;
  } else {
    message = `We hebben uw beroep tegen de beslissing goed ontvangen op ${formatDate(
      appealRequestDate
    )}. We onderzoeken uw beroep en gaan na of het gegrond is. Uiterlijk op <strong>${formatDate(
      appealDecisionDate
    )}</strong>  ontvangt u onze beslissing.`;
  }

  return {
    id: "FO_BEROEP_STARTED",
    title: "Beroep ingediend",
    message: message,
    type: notificationTypes.SUCCESS,
  };
};

export const notificationToModifyRequestor = ({
  values: { deadlineForModificationRequestor },
}) => ({
  id: "REQUESTOR_CAN_MODIFY_REQUESTOR",
  title: "Wijzigen aanvrager",
  message: `U vraagt een premie aan als eigenaar-bewoner. Na controle stellen we vast dat u niet de juiste eigendomsrechten heeft en/of niet gedomicilieerd bent in de premiewoning. Hierdoor behoort u automatisch toe aan de doelgroep ‘niet eigenaar-bewoner’. Bijgevolg zal u dan ook de premie voor deze doelgroep ontvangen. Dit is de laagste premie voor uitsluitend energiebesparende werken.</br></br>
   Als een ander meerderjarig persoon met wie u samenwoont wel beschikt over de juiste eigendomsrechten én gedomicilieerd is in de premiewoning, kan deze persoon een nieuwe premieaanvraag indienen zodat er mogelijk een hogere premie kan worden toegekend. Meer informatie over wie welke premies kan aanvragen en op welke premie elke doelgroep recht heeft, kunt u terugvinden op  <a href="www.mijnverbouwpremie.be">www.mijnverbouwpremie.be</a> en de simulator op deze pagina.</br></br>
   Gelieve ten laatste <strong>${formatDate(
     deadlineForModificationRequestor
   )}</strong> aan te geven via de beschikbare dossieractie 'Wijzigen aanvrager?' op dit dossieroverzicht of u met deze aanvraag wenst door te gaan of dat een ander persoon die eigenaar-bewoner is de premieaanvraag zal indienen.`,
  type: notificationTypes.WARNING,
});
