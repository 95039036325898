import React, { useEffect, useState } from "react";
import moment from "moment";
import { get, map, orderBy } from "lodash";
import PropTypes from "prop-types";
import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import { connect } from "react-redux";
import { fetchWrpProfile } from "../../../store/actions";
import { is1700Employee } from "../../../store/selectors";
import contactInformation from "../../../constants/contactInformation";
import milestoneDefinitionKeys from "../../../constants/epc/milestoneDefinitionKeys";
import documentDefinitionKeys from "../../../constants/epc/documentDefinitionKeys";
import {
  notificationAdditionalAttachmentsDelivered,
  notificationAdditionalAttachmentsDeliveredBeroep,
  notificationAdditionalAttachmentsNeeded,
  notificationAdditionalAttachmentsNeededBeroep,
  notificationBeroepRequestSubmitted,
  notificationFetchingDataFailed,
  notificationRequestNotSubmitted,
  notificationRequestSubmitted,
  notifications,
  notitificationAppealWaitingTooLong,
  notitificationBeroepDecided,
  notitificationDossierDecided,
} from "../notificationHelpers";
import {
  accountNumber,
  applicant,
  communication_only_email,
  eanNumberAndAdress,
  statusEPC,
  submitDate,
} from "../dossierPageHelpers";

//link milestone to notification
const notificationsForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA_FAILED]: notificationFetchingDataFailed,
  [milestoneDefinitionKeys.FETCH_DATA_FAILED_EPC]:
    notificationFetchingDataFailed,
  [milestoneDefinitionKeys.FILLING_IN_REQUEST]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.REQUEST_PART_1_SUBMITTED]:
    notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.CALL_EPC_API_FINISHED]:
    notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.REQUEST_PART_2_SUBMITTED]:
    notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED]:
    notificationAdditionalAttachmentsNeeded,
  [milestoneDefinitionKeys.BEROEP_TEGEN_STILZITTEN_INGEDIEND]:
    notitificationAppealWaitingTooLong,
  [milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT]:
    notitificationDossierDecided,
  [milestoneDefinitionKeys.BEROEP_SUBMITTED]:
    notificationBeroepRequestSubmitted,
  [milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP]:
    notitificationBeroepDecided,
  [milestoneDefinitionKeys.BEROEP_NOT_VALID]: notitificationBeroepDecided,
  [milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP]:
    notificationAdditionalAttachmentsNeededBeroep,
};

const loaderLabelForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.FETCH_DATA]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_PART_1_SUBMITTED]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_SUBMITTED]: "We dienen uw aanvraag in.",
};

export function epcDossierPageWrapper() {
  function EpcDossierPage(props) {
    function dossierProgressStepsToPass() {
      if (props.dossierDetails == undefined) return;
      const milestones = orderBy(props.dossierDetails.milestones, "timestamp", [
        "desc",
      ]);

      const dossierProgressSteps = [
        {
          name: "Aanvraag invullen",
          relatedMilestoneKey: "aanvraagInvullen",
          description: "Vul uw aanvraag in.",
        },
        {
          name: "Aanvraag ingediend",
          relatedMilestoneKey: "aanvraagIngediend",
          description: "Uw aanvraag is ingediend.",
        },
      ];

      let lastDossierStepReached = null;

      for (
        let milestoneIndex = 0;
        milestoneIndex < milestones.length;
        milestoneIndex++
      ) {
        lastDossierStepReached = get(
          dossierProgressSteps.find(
            (step) =>
              step.relatedMilestoneKey === milestones[milestoneIndex].key
          ),
          "relatedMilestoneKey"
        );
        if (lastDossierStepReached) {
          break;
        }
      }

      return map(dossierProgressSteps, (step) => {
        return Object.assign({}, step, {
          active: step.relatedMilestoneKey === lastDossierStepReached,
        });
      });
    }

    const [loaderLabel, setLoaderLabel] = useState(
      loaderLabelForMilestones[props.getLatestMilestoneKey()]
    );

    const [fetchCurrentMilestoneInterval, setFetchCurrentMilestoneInterval] =
      useState(false);

    const [
      loadingDossierDetailsAfterMilestonesInterval,
      setLoadingDossierDetailsAfterMilestonesInterval,
    ] = useState(false);

    const dossierId = props.dossier.id;
    const fetchDossierDetails = props.fetchDossierDetails;
    const fetchCurrentDossierMilestone = props.fetchCurrentDossierMilestone;

    /* The current milestone is fetched every so seconds until no loaderLabel is defined for the current milestone */
    useEffect(() => {
      function cleanup() {
        if (fetchCurrentMilestoneInterval) {
          setFetchCurrentMilestoneInterval(false);
          clearInterval(fetchCurrentMilestoneInterval);

          // just to be sure, we have the latest dossier, we do one fetch of the dossier details
          setLoadingDossierDetailsAfterMilestonesInterval(true);
          fetchDossierDetails(dossierId).then(() =>
            setLoadingDossierDetailsAfterMilestonesInterval(false)
          );
        }
      }

      // getLatestMilestoneKey is based on the dossier details; here we're fetching the currentMilestone separately, so we need to watch that one
      const currentMilestone = props.currentMilestone?.key;
      const newLoaderLabel = loaderLabelForMilestones[currentMilestone];
      setLoaderLabel(loaderLabelForMilestones[currentMilestone]);

      if (newLoaderLabel && !fetchCurrentMilestoneInterval) {
        setFetchCurrentMilestoneInterval(
          setInterval(() => {
            fetchCurrentDossierMilestone(dossierId);
          }, 2000)
        );
      }
      if (!newLoaderLabel && fetchCurrentMilestoneInterval) {
        cleanup();
      }
      return () => {
        cleanup();
      };
    }, [
      fetchCurrentMilestoneInterval,
      loaderLabel,
      dossierId,
      fetchDossierDetails,
      fetchCurrentDossierMilestone,
      props.currentMilestone,
    ]);

    const { fetchWrpProfile } = props;

    useEffect(() => {
      fetchWrpProfile();
    }, [fetchWrpProfile]);

    function getActiveTasks() {
      if (props.loadingDossierDetails) return;
      const allTasks = get(props, ["dossierDetails", "task"], []);
      return allTasks.filter(function (task) {
        return task.active;
      });
    }

    //constants needed for notifications
    const dossierType = props.dossier.dossierDefinitionKey;

    const valuesForNotifications = {
      requestDate: moment(
        props.getFieldFromDocument(documentDefinitionKeys.EPC_REQUEST_PART_1, [
          "aanvraagdatum",
        ])
      ),
      decisionDate: moment(
        props.getFieldFromDocument(documentDefinitionKeys.EPC_REQUEST_PART_1, [
          "aanvraagdatum",
        ])
      ).add(3, "M"),
      additionalAttachmentsRequested: moment(
        props.getFieldFromDocument(documentDefinitionKeys.EPC_WACHTTIJD_BS, [
          "wachttijd",
        ])
      ),
      additionalAttachmentsDelivered: moment(
        props.getMilestone(
          milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
        )?.timestamp
      ),
      appealRequestDate: moment(
        props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)?.timestamp
      ),
      appealDecisionDate: moment(
        props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)?.timestamp
      ).add(3, "M"),
    };

    const getNotificationForLatestMilestone = () => {
      // If there is a specified notification for the milestone, show this notification (see list of constants at top of page)
      let notificationForLatestMilestone =
        notificationsForMilestones[props.getLatestMilestoneKey()];
      // If there is no notification for the current milestone, and the milestone is not request submitted
      // go through these rules below to figure out what notification has to be shown
      if (
        !notificationForLatestMilestone &&
        props.getLatestMilestoneKey() !==
          milestoneDefinitionKeys.REQUEST_SUBMITTED
      ) {
        if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED_BEROEP
          )
        ) {
          notificationForLatestMilestone =
            notificationAdditionalAttachmentsDeliveredBeroep;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
          ) &&
          props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_DELIVERED
          )
        ) {
          notificationForLatestMilestone =
            notificationAdditionalAttachmentsDelivered;
        } else if (
          props.getMilestone(
            milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
          ) ||
          props.getMilestone(milestoneDefinitionKeys.BEROEP_NOT_VALID)
        ) {
          notificationForLatestMilestone = notitificationBeroepDecided;
        } else if (
          props.getMilestone(milestoneDefinitionKeys.BEROEP_SUBMITTED)
        ) {
          notificationForLatestMilestone = notificationBeroepRequestSubmitted;
        } else if (
          props.getMilestone(milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT)
        ) {
          notificationForLatestMilestone = notitificationDossierDecided;
        } else {
          notificationForLatestMilestone = notificationRequestSubmitted;
        }
      }
      return notificationForLatestMilestone;
    };

    const milestonesForStatus = {
      latestMilestoneKey: props.getLatestMilestoneKey(),
      additonalAttachmentRequestedMilestoneKey:
        milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED,
      additionalAttachmentRequestedBeroepMilestoneKey:
        milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED_BEROEP,
      milestoneDecisionLetterToClient: props.getMilestone(
        milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
      ),
      milestoneRequestSubmitted: props.getMilestone(
        milestoneDefinitionKeys.REQUEST_SUBMITTED
      ),
      milestoneDecisionLetterToClientBeroep: props.getMilestone(
        milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT_BEROEP
      ),
      milestoneBeroepNotValid: props.getMilestone(
        milestoneDefinitionKeys.BEROEP_NOT_VALID
      ),
      milestoneBeroepSubmitted: props.getMilestone(
        milestoneDefinitionKeys.BEROEP_SUBMITTED
      ),
      milestoneBeroepStarted: props.getMilestone(
        milestoneDefinitionKeys.BEROEP_STARTED
      ),
    };

    //dossierdetails
    function dossierInformation() {
      return [
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? []
          : [
              {
                label: "Aangemaakt op",
                value: moment(get(props.dossier, ["createdAt"])).format(
                  "D MMMM YYYY"
                ),
              },
            ]),
        {
          label: "Status",
          value: statusEPC(milestonesForStatus),
        },
        {
          label: "Ingediend op",
          value: submitDate({
            requestSubmittedMilestone: props.getMilestone(
              milestoneDefinitionKeys.REQUEST_SUBMITTED
            ),
            requestDate: props.getFieldFromDocument(
              documentDefinitionKeys.EPC_REQUEST_PART_1,
              ["aanvraagdatum"]
            ),
          }),
        },
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "EAN en adres",
                value: eanNumberAndAdress({
                  eanNumber: props.getFieldFromDocument(
                    documentDefinitionKeys.EPC_REQUEST_PART_1,
                    ["eanAndAddress", "eanCodeWoning"]
                  ),
                  adress: props.getFieldFromDocument(
                    documentDefinitionKeys.EPC_REQUEST_PART_1,
                    ["uitvoeringsadres"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Communicatie",
                value: communication_only_email({
                  email: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["emailAdres"]
                  ),
                  phoneNumber: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["telefoonnummer"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getMilestone(
          milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED
        ) &&
        accountNumber({
          belgischRekeningNummer: props.getFieldFromDocument(
            documentDefinitionKeys.CONTACT_DATA,
            ["accountnumber"]
          ),
          geenBelgischRekeningNummer: props.getFieldFromDocument(
            documentDefinitionKeys.CONTACT_DATA,
            ["geenBelgischRekeningnummer"]
          ),
          buitenlandsRekeningnummer: props.getFieldFromDocument(
            documentDefinitionKeys.CONTACT_DATA,
            ["buitenlandsRekeningnummer"]
          ),
        })
          ? [
              {
                label: "Rekeningnummer",
                value: accountNumber({
                  belgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["accountnumber"]
                  ),
                  geenBelgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["geenBelgischRekeningnummer"]
                  ),
                  buitenlandsRekeningnummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["buitenlandsRekeningnummer"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getDocument(documentDefinitionKeys.EPC_LOGIN_DATA)
          ? [
              {
                label: "Aanvrager",
                value: applicant({
                  naamOrganisatie: props.getFieldFromDocument(
                    documentDefinitionKeys.EPC_LOGIN_DATA,
                    ["naamOrganisatie"]
                  ),
                  voornaam: props.getFieldFromDocument(
                    documentDefinitionKeys.EPC_LOGIN_DATA,
                    ["voornaam"]
                  ),
                  naam: props.getFieldFromDocument(
                    documentDefinitionKeys.EPC_LOGIN_DATA,
                    ["naam"]
                  ),
                  naamOrganisatieMandaatnemer: props.getFieldFromDocument(
                    documentDefinitionKeys.EPC_LOGIN_DATA,
                    ["naamOrganisatieMandaatnemer"]
                  ),
                }),
              },
            ]
          : []),
      ];
    }

    function downloads() {
      const downloads = [];

      // "condition": what has to be true to show the communication
      // "communication": the name of the communication
      // "displayName": what name has to be shown for this communication

      const communicationsToAdd = [
        {
          condition:
            props.getCommunication("beslissingsbriefEPC") &&
            props.getMilestone(
              milestoneDefinitionKeys.DECISION_LETTER_TO_CLIENT
            ),
          communication: "beslissingsbriefEPC",
          displayName: "Beslissingsbrief",
        },
        {
          condition: props.getMilestone(
            milestoneDefinitionKeys.DETAIL_DECISION_LETTER_SENT
          ),
          communication: "detailBeslissingEPC",
          displayName: "Detail beslissing",
        },
        {
          condition: props.getMilestone(
            milestoneDefinitionKeys.ADDITIONAL_ATTACHMENTS_REQUESTED
          ),
          communication: "bijkomendeStukkenEPC",
          displayName: "Bijkomende stukken aan te leveren",
        },
        {
          condition: props.getCommunication("ontvangstbevestigingEPC"),
          communication: "ontvangstbevestigingEPC",
          displayName: "Ontvangstbevestiging",
        },
        {
          condition: props.getCommunication("ontvangstbevestigingBeroepEpc"),
          communication: "ontvangstbevestigingBeroepEpc",
          displayName: "Ontvangstbevestiging beroep",
        },
        {
          condition: props.getCommunication("beslissingsbriefBeroepGeweigerd"),
          communication: "beslissingsbriefBeroepGeweigerd",
          displayName: "Beslissingsbrief beroep geweigerd",
        },
      ];

      communicationsToAdd.forEach(
        ({ condition, communication, displayName }) => {
          if (condition) {
            downloads.push(
              props.mapCommunicationToPdfDownloadInfo(
                props.getCommunication(communication),
                displayName
              )
            );
          }
        }
      );

      return downloads;
    }

    const propsToPass = {
      ...props,
      loaderLabel,
      loadingDossierDetails:
        props.loadingDossierDetails ||
        loadingDossierDetailsAfterMilestonesInterval,
      contactInformation:
        contactInformation[props.dossier.dossierDefinitionKey] ||
        contactInformation["default"],
      notifications: notifications({
        notification: getNotificationForLatestMilestone(),
        values: valuesForNotifications,
        dossierType,
      }),
      dossierInformation: dossierInformation(),
      downloads: downloads(),
      getMyActiveTasksInDossier: getActiveTasks,
      //executeTask: executeTask,
    };

    return (
      <>
        <CoreDossierPage
          {...propsToPass}
          getDossierProgressSteps={dossierProgressStepsToPass}
        />
      </>
    );
  }

  EpcDossierPage.propTypes = {
    ...externalProps,
    ...internalProps,
    dossier: PropTypes.shape({
      ...externalProps.dossier,
      dossierDefinitionKey: PropTypes.string,
    }),
  };

  return EpcDossierPage;
}

const mapStateToProps = (state) => ({
  is1700Employee: is1700Employee(state),
});

export default coreDossierPageWrapper(
  connect(mapStateToProps, { fetchWrpProfile })(epcDossierPageWrapper())
);
