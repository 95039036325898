import React, { useEffect, useState } from "react";
import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";
import { map } from "lodash";
import moment from "moment";
import classnames from "classnames";
import { connect } from "react-redux";
import { fetchWrpProfile } from "../../../store/actions";
import { is1700Employee } from "../../../store/selectors";

import contactInformation from "../../../constants/contactInformation";
import milestoneDefinitionKeys from "../../../constants/mvl/milestoneDefinitionKeys";
import documentDefinitionKeys from "../../../constants/mvl/documentDefinitionKeys";
import categoryIcons from "../../../constants/iconsForCategories";
import {
  accountNumber,
  categoryList,
  applicant,
  submitDate,
  status,
  eanNumberAndAdress,
  communication,
} from "../dossierPageHelpers";
import {
  notifications,
  notificationRequestNotSubmitted,
  notificationRequestSubmitted,
  notitificationDossierRejected,
} from "../notificationHelpers";

import PropTypes from "prop-types";

//constants
const MIJN_ENERGIEHUIS_URL = "https://www.mijnenergiehuis.be";

//link milestone to notification
const notificationsForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FETCH_DATA_CITIZEN]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.FILLING_IN_REQUEST]: notificationRequestNotSubmitted,
  [milestoneDefinitionKeys.REQUEST_SUBMITTED]: notificationRequestSubmitted,
  [milestoneDefinitionKeys.DOSSIER_DEF_REJECTED_MVL]:
    notitificationDossierRejected,
};

//needed for loader
const loaderLabelForMilestones = {
  [milestoneDefinitionKeys.REQUEST_INITIATED]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.FETCH_DATA_CITIZEN]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_INITIATED_IN_BO]:
    "We halen enkele gegevens voor u op bij een externe dienst. Dit kan even duren.",
  [milestoneDefinitionKeys.REQUEST_SUBMITTED]: "We dienen uw aanvraag in.",
};

export function mvlDossierPageWrapper() {
  function MvlDossierPage(props) {
    // loader
    const [loaderLabel, setLoaderLabel] = useState(
      loaderLabelForMilestones[props.getLatestMilestoneKey()]
    );
    const [fetchCurrentMilestoneInterval, setFetchCurrentMilestoneInterval] =
      useState(false);
    const [
      loadingDossierDetailsAfterMilestonesInterval,
      setLoadingDossierDetailsAfterMilestonesInterval,
    ] = useState(false);

    const dossierId = props.dossier.id;
    const fetchDossierDetails = props.fetchDossierDetails;
    const fetchCurrentDossierMilestone = props.fetchCurrentDossierMilestone;

    /* The current milestone is fetched every so seconds until no loaderLabel is defined for the current milestone */
    useEffect(() => {
      function cleanup() {
        if (fetchCurrentMilestoneInterval) {
          setFetchCurrentMilestoneInterval(false);
          clearInterval(fetchCurrentMilestoneInterval);

          // just to be sure, we have the latest dossier, we do one fetch of the dossier details
          setLoadingDossierDetailsAfterMilestonesInterval(true);
          fetchDossierDetails(dossierId).then(() =>
            setLoadingDossierDetailsAfterMilestonesInterval(false)
          );
        }
      }

      // getLatestMilestoneKey is based on the dossier details; here we're fetching the currentMilestone separately, so we need to watch that one
      const currentMilestone = props.currentMilestone?.key;
      const newLoaderLabel = loaderLabelForMilestones[currentMilestone];
      setLoaderLabel(loaderLabelForMilestones[currentMilestone]);

      if (newLoaderLabel && !fetchCurrentMilestoneInterval) {
        setFetchCurrentMilestoneInterval(
          setInterval(() => {
            fetchCurrentDossierMilestone(dossierId);
          }, 2000)
        );
      }

      if (!newLoaderLabel && fetchCurrentMilestoneInterval) {
        cleanup();
      }
      return () => {
        cleanup();
      };
    }, [
      fetchCurrentMilestoneInterval,
      loaderLabel,
      dossierId,
      fetchDossierDetails,
      fetchCurrentDossierMilestone,
      props.currentMilestone,
    ]);

    const { fetchWrpProfile } = props;

    useEffect(() => {
      fetchWrpProfile();
    }, [fetchWrpProfile]);

    // dossierInformation
    function dossierInformation() {
      return [
        {
          label: "Status",
          value: status(milestonesForStatus),
        },
        {
          label: "Ingediend op",
          value: submitDate({
            requestSubmittedMilestone: props.getMilestone(
              milestoneDefinitionKeys.REQUEST_SUBMITTED
            ),
            requestDate: props.getFieldFromDocument(
              documentDefinitionKeys.REQUEST,
              ["aanvraagdatum"]
            ),
          }),
        },
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Adres",
                value: eanNumberAndAdress({
                  eanNumber: null,
                  adress: props.getFieldFromDocument(
                    documentDefinitionKeys.REQUEST,
                    ["uitvoeringsadres"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Communicatie",
                value: communication({
                  commucationOption: null,
                  communicationOptionLabel: null,
                  otherOptionLabel: null,
                  email: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["emailAdres"]
                  ),
                  phoneNumber: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["telefoonnummer"]
                  ),
                  isPostOption: false,
                  deliveryAdress: null,
                  mailAdressOtherParty: null,
                }),
              },
            ]
          : []),
        ...(props.getMilestone(milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED)
          ? [
              {
                label: "Rekeningnummer",
                value: accountNumber({
                  belgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["accountnumber"]
                  ),
                  geenBelgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["geenBelgischRekeningnummer"]
                  ),
                  buitenlandsRekeningnummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["buitenlandsRekeningnummer"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getMilestone(
          milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED
        ) &&
        props.getFieldFromDocument(documentDefinitionKeys.CONTACT_DATA, [
          "accountnumberEnkelOntvangen",
        ])
          ? [
              {
                label: "Rekeningnummer uitbetalingen",
                value: accountNumber({
                  belgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["accountnumberEnkelOntvangen"]
                  ),
                  geenBelgischRekeningNummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["geenBelgischRekeningnummerOntvangenLening"]
                  ),
                  buitenlandsrekeningnummer: props.getFieldFromDocument(
                    documentDefinitionKeys.CONTACT_DATA,
                    ["buitenlandsRekeningnummerOntvangenLening"]
                  ),
                }),
              },
            ]
          : []),
        ...(props.getDocument(documentDefinitionKeys.LOGIN_DATA)
          ? [
              {
                label: "Aanvrager",
                value: applicant({
                  naamOrganisatie: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["naamOrganisatie"]
                  ),
                  voornaam: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["voornaam"]
                  ),
                  naam: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["naam"]
                  ),
                  naamOrganisatieMandaatnemer: props.getFieldFromDocument(
                    documentDefinitionKeys.LOGIN_DATA,
                    ["naamOrganisatieMandaatnemer"]
                  ),
                }),
              },
            ]
          : []),
        {
          label: "Energiehuis",
          value: getEnergyHouse(),
        },
        ...(props.getMilestone(milestoneDefinitionKeys.DOSSIER_DEF_REJECTED_MVL)
          ? [
              {
                label: "Beslissing per categorie",
                value: categoryTable(),
              },
            ]
          : props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
          ? [
              {
                label: "Categorieën van werken",
                value: categoryList({
                  categories: props.getFieldFromDocument(
                    documentDefinitionKeys.REQUEST,
                    [
                      "selectieCategorieenFieldset",
                      "selectieCategorieen",
                      "selectedOptions",
                    ]
                  ),
                  categoryLabels: map(
                    props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
                      "selectieCategorieenFieldset",
                      "selectieCategorieen",
                      "selectedOptionsLabels",
                    ]),
                    (labels) => labels.nl
                  ),
                }),
              },
            ]
          : []),
      ];
    }

    // DossierInformationHelpers that are specific for mvl

    // Show the energyhouse and a link to the corresponding energyhouse website
    function getEnergyHouse() {
      const energyHouse = props.getFieldFromDocument(
        documentDefinitionKeys.ENERGYHOUSE_DATA,
        ["naam", "selectedOptionLabel", "nl"]
      );
      const energyHouseLink = props.getFieldFromDocument(
        documentDefinitionKeys.ENERGYHOUSE_DATA,
        ["hyperlink"]
      );

      if (energyHouse) {
        return (
          <p key="energyHouse" style={{ alignItems: "center" }}>
            <a
              href={energyHouseLink}
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: "normal" }}
            >
              {energyHouse}
            </a>
          </p>
        );
      }
      return (
        <p key="energyHouse" style={{ alignItems: "center" }}>
          <a
            href={MIJN_ENERGIEHUIS_URL}
            target="_blank"
            rel="noreferrer"
            style={{ fontWeight: "normal" }}
          >
            {MIJN_ENERGIEHUIS_URL}
          </a>
        </p>
      );
    }

    // When the dossier is decided, show the categories and the decision per category (approved, rejected)
    // In current scope, when dossier is decided, categories will always be rejected
    function categoryTable() {
      const categories = props.getFieldFromDocument(
        documentDefinitionKeys.REQUEST,
        [
          "selectieCategorieenFieldset",
          "selectieCategorieen",
          "selectedOptions",
        ]
      );

      const categoryLabels = map(
        props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
          "selectieCategorieenFieldset",
          "selectieCategorieen",
          "selectedOptionsLabels",
        ]),
        (labels) => labels.nl
      );

      // in current scope category will always be rejected when dossier is decided
      function decisionPill() {
        return (
          <div className="vl-pill vl-pill--error">
            <span className="vl-pill__text">Geweigerd</span>
          </div>
        );
      }

      return (
        <table className="vl-data-table vl-data-table--">
          <thead>
            <tr>
              <th>Categorie</th>
              <th>Beslissing</th>
            </tr>
          </thead>
          <tbody>
            {map(categories, (categoryKey, index) => (
              <tr key={categoryKey}>
                <td data-title="Categorie">
                  <p
                    className="vl-icon-wrapper"
                    style={{ alignItems: "center" }}
                  >
                    <span
                      className={classnames(
                        "vl-icon vl-icon--before",
                        categoryIcons[categoryKey]
                      )}
                      aria-hidden="true"
                    ></span>
                    <span>{categoryLabels[index]}</span>
                  </p>
                </td>
                <td data-title="Beslissing">{decisionPill()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    //constants needed for notifications
    const dossierType = props.dossier.dossierDefinitionKey;

    const valuesForNotifications = {
      requestDate: moment(
        props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
          "aanvraagdatum",
        ])
      ),
      decisionDate: moment(
        props.getFieldFromDocument(documentDefinitionKeys.REQUEST, [
          "aanvraagdatum",
        ])
      ).add(3, "M"),
    };

    // If there is a specified notification for the milestone, show this notification (see list of constants at top of page)
    let notificationForLatestMilestone =
      notificationsForMilestones[props.getLatestMilestoneKey()];

    // If there is no notification for the current milestone, and the milestone is not request submitted
    // go through these rules below to figure out what notification has to be shown
    if (
      !notificationForLatestMilestone &&
      props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)
    ) {
      notificationForLatestMilestone = notificationRequestSubmitted;
    }

    //downloads
    function downloads() {
      const downloads = [];

      //TODO add "aanvraagOverzicht"
      const communicationsToAdd = [
        {
          condition: props.getCommunication("ontvangstbevestiging_mvl"),
          communication: "ontvangstbevestiging_mvl",
          displayName: "Ontvangstbevestiging",
        },
        {
          condition: props.getMilestone(
            milestoneDefinitionKeys.DOSSIER_DEF_REJECTED_MVL
          ),
          communication: "weigeringsbrief_mvl",
          displayName: "Beslissingsbrief",
        },
      ];

      // "AanvraagOverzicht" is an exception and has to be added in another way
      if (props.getMilestone(milestoneDefinitionKeys.REQUEST_SUBMITTED)) {
        downloads.push(
          props.mapDocumentToPdfDownloadInfo(
            props.getDocument(documentDefinitionKeys.REQUEST),
            "Aanvraag",
            "aanvraagOverzicht_mvl"
          )
        );
      }

      communicationsToAdd.forEach(
        ({ condition, communication, displayName }) => {
          if (condition) {
            downloads.push(
              props.mapCommunicationToPdfDownloadInfo(
                props.getCommunication(communication),
                displayName
              )
            );
          }
        }
      );

      return downloads;
    }

    //status

    const milestonesForStatus = {
      latestMilestoneKey: props.getLatestMilestoneKey(),
      milestoneDossierDefRejectedMvl: props.getMilestone(
        milestoneDefinitionKeys.DOSSIER_DEF_REJECTED_MVL
      ),
      milestoneRequestSubmitted: props.getMilestone(
        milestoneDefinitionKeys.REQUEST_SUBMITTED
      ),
    };

    const propsToPass = {
      ...props,
      // loaderLabel,
      loadingDossierDetails:
        props.loadingDossierDetails ||
        loadingDossierDetailsAfterMilestonesInterval,
      contactInformation:
        contactInformation[props.dossier.dossierDefinitionKey] ||
        contactInformation["default"],
      notifications: notifications({
        notification: notificationForLatestMilestone,
        values: valuesForNotifications,
        dossierType,
      }),
      dossierInformation: dossierInformation(),
      downloads: downloads(),
      //getMyActiveTasksInDossier: getActiveTasks,
      //executeTask: executeTask,
    };

    return (
      <>
        <CoreDossierPage {...propsToPass} />
      </>
    );
  }

  MvlDossierPage.propTypes = {
    ...externalProps,
    ...internalProps,
    dossier: PropTypes.shape({
      ...externalProps.dossier,
      dossierDefinitionKey: PropTypes.string,
    }),
  };

  return MvlDossierPage;
}

const mapStateToProps = (state) => ({
  is1700Employee: is1700Employee(state),
});

export default coreDossierPageWrapper(
  connect(mapStateToProps, { fetchWrpProfile })(mvlDossierPageWrapper())
);
