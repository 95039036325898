import React from "react";
import { map } from "lodash";
import moment from "moment";

import categoryIcons from "../../constants/iconsForCategories";
import classnames from "classnames";

export function accountNumber({
  belgischRekeningNummer,
  geenBelgischRekeningNummer,
  buitenlandsRekeningnummer,
}) {
  if (!belgischRekeningNummer && !buitenlandsRekeningnummer) return null;
  return geenBelgischRekeningNummer
    ? buitenlandsRekeningnummer
    : "BE" +
        belgischRekeningNummer.substring(0, 2) +
        " " +
        belgischRekeningNummer.substring(2, 6) +
        " " +
        belgischRekeningNummer.substring(6, 10) +
        " " +
        belgischRekeningNummer.substring(10, 14);
}

export function categoryList({ categories, categoryLabels }) {
  return (
    <div>
      {map(categories, (categoryKey, index) => (
        <p
          key={categoryKey}
          className="vl-icon-wrapper"
          style={{ alignItems: "center" }}
        >
          <span
            className={classnames(
              "vl-icon vl-icon--before",
              categoryIcons[categoryKey]
            )}
            aria-hidden="true"
          ></span>
          <span>{categoryLabels[index]}</span>
        </p>
      ))}
    </div>
  );
}

export function applicant({
  naamOrganisatie,
  voornaam,
  naam,
  naamOrganisatieMandaatnemer,
}) {
  const aanvrager = voornaam + " " + naam;

  if (naamOrganisatie && naamOrganisatieMandaatnemer) {
    return (
      <div>
        <p key="naamOrganisatie" style={{ alignItems: "center" }}>
          <span>{naamOrganisatie}</span>
        </p>
        <p key="naamOrganisatieMandaatnemer" style={{ alignItems: "center" }}>
          <span>{" door "}</span>
          <span>{naamOrganisatieMandaatnemer}</span>
        </p>
      </div>
    );
  } else if (naamOrganisatieMandaatnemer) {
    return (
      <div>
        <p key="aanvragerNaam" style={{ alignItems: "center" }}>
          <span>{aanvrager}</span>
        </p>
        <p key="naamOrganisatieMandaatnemer" style={{ alignItems: "center" }}>
          <span>{" door "}</span>
          <span>{naamOrganisatieMandaatnemer}</span>
        </p>
      </div>
    );
  } else if (naamOrganisatie) {
    return naamOrganisatie;
  }

  return aanvrager;
}

export function submitDate({ requestSubmittedMilestone, requestDate }) {
  if (requestSubmittedMilestone) {
    return moment(requestDate).format("D MMMM YYYY");
  } else {
    return "Nog niet ingediend";
  }
}

export function status({
  latestMilestoneKey,
  additonalAttachmentRequestedMilestoneKey,
  additionalAttachmentRequestedBeroepMilestoneKey,
  milestoneUitbetalingVoltooidBeroep,
  milestoneDecisionLetterToClientBeroep,
  milestoneBeroepNotValid,
  milestoneBeroepStarted,
  milestoneUitbetalingVoltooid,
  milestoneBeroepSubmitted,
  milestoneDecisionLetterToClient,
  milestoneRequestSubmitted,
  milestoneDossierDefRejectedMvl,
}) {
  if (
    latestMilestoneKey !== undefined &&
    latestMilestoneKey === additonalAttachmentRequestedMilestoneKey
  ) {
    return (
      <div className="vl-pill vl-pill--error">
        <span className="vl-pill__text">Dossier onvolledig</span>
      </div>
    );
  } else if (
    latestMilestoneKey &&
    latestMilestoneKey === additionalAttachmentRequestedBeroepMilestoneKey
  ) {
    return (
      <div className="vl-pill vl-pill--error">
        <span className="vl-pill__text">Beroep: Dossier onvolledig</span>
      </div>
    );
  } else if (milestoneUitbetalingVoltooidBeroep) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beroep uitbetaald</span>
      </div>
    );
  } else if (milestoneDecisionLetterToClientBeroep || milestoneBeroepNotValid) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beroep beslist</span>
      </div>
    );
  } else if (milestoneBeroepStarted) {
    return (
      <div className="vl-pill vl-pill--warning">
        <span className="vl-pill__text">Beroep in behandeling</span>
      </div>
    );
  } else if (milestoneUitbetalingVoltooid) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Dossier uitbetaald</span>
      </div>
    );
  } else if (milestoneBeroepSubmitted) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beroep ingediend</span>
      </div>
    );
  } else if (milestoneDecisionLetterToClient) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beslist</span>
      </div>
    );
  } else if (milestoneDossierDefRejectedMvl) {
    return (
      <div className="vl-pill vl-pill--error">
        <span className="vl-pill__text">Beslist</span>
      </div>
    );
  } else if (milestoneRequestSubmitted) {
    return (
      <div className="vl-pill vl-pill--warning">
        <span className="vl-pill__text">In behandeling</span>
      </div>
    );
  } else {
    return (
      <div className="vl-pill">
        <span className="vl-pill__text">Concept</span>
      </div>
    );
  }
}

export function statusEPC({
  latestMilestoneKey,
  additonalAttachmentRequestedMilestoneKey,
  additionalAttachmentRequestedBeroepMilestoneKey,
  milestoneUitbetalingVoltooidBeroep,
  milestoneDecisionLetterToClientBeroep,
  milestoneBeroepNotValid,
  milestoneBeroepStarted,
  milestoneUitbetalingVoltooid,
  milestoneBeroepSubmitted,
  milestoneDecisionLetterToClient,
  milestoneRequestSubmitted,
  milestoneDossierDefRejectedMvl,
}) {
  if (
    latestMilestoneKey !== undefined &&
    latestMilestoneKey === additonalAttachmentRequestedMilestoneKey
  ) {
    return (
      <div className="vl-pill vl-pill--error">
        <span className="vl-pill__text">Dossier onvolledig</span>
      </div>
    );
  } else if (
    latestMilestoneKey &&
    latestMilestoneKey === additionalAttachmentRequestedBeroepMilestoneKey
  ) {
    return (
      <div className="vl-pill vl-pill--error">
        <span className="vl-pill__text">Beroep: Dossier onvolledig</span>
      </div>
    );
  } else if (milestoneUitbetalingVoltooidBeroep) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beroep uitbetaald</span>
      </div>
    );
  } else if (milestoneDecisionLetterToClientBeroep || milestoneBeroepNotValid) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beroep beslist</span>
      </div>
    );
  } else if (milestoneBeroepStarted) {
    return (
      <div className="vl-pill vl-pill--warning">
        <span className="vl-pill__text">Beroep in behandeling</span>
      </div>
    );
  } else if (milestoneUitbetalingVoltooid) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Dossier uitbetaald</span>
      </div>
    );
  } else if (milestoneBeroepSubmitted) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beroep ingediend</span>
      </div>
    );
  } else if (milestoneDecisionLetterToClient) {
    return (
      <div className="vl-pill vl-pill--success">
        <span className="vl-pill__text">Beslist</span>
      </div>
    );
  } else if (milestoneDossierDefRejectedMvl) {
    return (
      <div className="vl-pill vl-pill--error">
        <span className="vl-pill__text">Beslist</span>
      </div>
    );
  } else if (milestoneRequestSubmitted) {
    return (
      <div className="vl-pill vl-pill--warning">
        <span className="vl-pill__text">In behandeling</span>
      </div>
    );
  } else {
    return (
      <div className="vl-pill">
        <span className="vl-pill__text">Concept</span>
      </div>
    );
  }
}

export function eanNumberAndAdress({ eanNumber, adress }) {
  let eanFormatted = "";

  if (eanNumber) {
    eanFormatted =
      "54144" +
      " " +
      eanNumber.substring(0, 4) +
      " " +
      eanNumber.substring(4, 8) +
      " " +
      eanNumber.substring(8, 12) +
      " " +
      eanNumber.substring(12, 16) +
      " " +
      eanNumber.substring(17);
  }

  return (
    <div>
      {eanNumber ? (
        <>
          <p key="adress1" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>EAN-nummer: </span>
            <span>{eanFormatted} </span>
          </p>
        </>
      ) : null}
      <p key="adress2" style={{ alignItems: "center" }}>
        {eanNumber ? (
          <>
            <span style={{ fontWeight: "normal" }}>Uitvoeringsadres: </span>
          </>
        ) : null}
        <span>{adress.street} </span>
        <span>{adress.housenumber}</span>
        {adress.boxnumber ? <span>/{adress.boxnumber}</span> : null}
      </p>
      <p key="adress3" style={{ alignItems: "center" }}>
        {eanNumber ? (
          <span style={{ visibility: "hidden" }}>Uitvoeringsadres: </span>
        ) : null}
        <span>{adress.zipcode} </span>
        <span>{adress.municipality}</span>
      </p>
    </div>
  );
}

export function communication({
  communicationOption,
  communicationOptionLabel,
  otherOptionLabel,
  email,
  phoneNumber,
  isPostOption,
  deliveryAdress,
  mailAdressOtherParty,
}) {
  if (communicationOption === "andereOpties") {
    communicationOptionLabel = otherOptionLabel;
  }

  const phoneNumberFormatted = formatPhoneNumber(phoneNumber);
  return (
    <div>
      {communicationOption ? (
        <>
          <p key="communicatieVoorkeur" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>
              Communicatie voorkeur:{" "}
            </span>
            <span>{communicationOptionLabel}</span>
          </p>
        </>
      ) : null}
      {email ? (
        <p key="email" style={{ alignItems: "center" }}>
          <span style={{ fontWeight: "normal" }}>E-mail: </span>
          <span>{email}</span>
        </p>
      ) : null}
      <p key="phoneNumber" style={{ alignItems: "center" }}>
        <span style={{ fontWeight: "normal" }}>Telefoonnummer: </span>
        <span>{phoneNumberFormatted}</span>
      </p>
      {isPostOption ? (
        <>
          <p key="deliveryAdress1" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>Afleveradres: </span>
            <span>{deliveryAdress.street} </span>
            <span>{deliveryAdress.housenumber}</span>
            {deliveryAdress.boxnumber ? (
              <span>/{deliveryAdress.boxnumber}</span>
            ) : null}
          </p>
          <p key="deliveryAdress2" style={{ alignItems: "center" }}>
            <span style={{ visibility: "hidden" }}>Afleveradres: </span>
            <span>{deliveryAdress.zipcode} </span>
            <span>{deliveryAdress.municipality}</span>
          </p>
        </>
      ) : null}
      {mailAdressOtherParty ? (
        <>
          <p key="mailAdressOtherParty" style={{ alignItems: "center" }}>
            <span style={{ fontWeight: "normal" }}>Bijkomende E-mail: </span>
            <span>{mailAdressOtherParty}</span>
          </p>
        </>
      ) : null}
    </div>
  );
}

export function communication_only_email({ email, phoneNumber }) {
  const phoneNumberFormatted = formatPhoneNumber(phoneNumber);
  return (
    <div>
      {email ? (
        <p key="email" style={{ alignItems: "center" }}>
          <span style={{ fontWeight: "normal" }}>E-mail: </span>
          <span>{email}</span>
        </p>
      ) : null}
      <p key="phoneNumber" style={{ alignItems: "center" }}>
        <span style={{ fontWeight: "normal" }}>Telefoonnummer: </span>
        <span>{phoneNumberFormatted}</span>
      </p>
    </div>
  );
}

function formatPhoneNumber(phoneNumber) {
  return (
    "+" +
    phoneNumber.substring(0, 2) +
    " " +
    phoneNumber.substring(2, 5) +
    " " +
    phoneNumber.substring(5, 7) +
    " " +
    phoneNumber.substring(7, 9) +
    " " +
    phoneNumber.substring(9, 11)
  );
}
